import { LaborOrder } from "../../../core/interfaces/labor-order.interface";
import { LaborOrderState } from "../../../shared/enums/labor-order-state.enum";
import { LaborOrdersFilters } from "../interfaces/labor-orders-filters.interface";

export class LoadOrders {
  public static readonly type = "[Orders] load";
  constructor(
    public skipCount: number, 
    public maxResult: number
  ) {}
}

export class CreateLaborOrder {
  public static readonly type = "[Orders] create";
  constructor(
    public order: LaborOrder,
    private callback: (error) => void
  ) {}
}

export class UpdateLaborOrder {
  public static readonly type = "[Orders] update";
  constructor(
    public order: LaborOrder,
    private callback: (error) => void
  ) {}
}

export class SaveAndSendLaborOrder {
  public static readonly type = "[Orders] save and send order";
  constructor(
    public order: LaborOrder,
    public callback: (error) => void
  ) {}
}

export class LaborOrderChangeState {
  public static readonly type = "[Orders] change state";
  constructor(
    public orderId: string,
    public orderState: LaborOrderState,
    public callback: (error) => void
  ) {}
}

export class SetCurrentOrder {
  public static readonly type = "[Orders] set current order";
  constructor(public orderId: string) {}
}

export class RemoveOrder {
  public static readonly type = "[Orders] remove";
  constructor(
    public order: LaborOrder,
    public callback: () => void
  ) {}
}

export class SaveDispatchNotes {
  public static readonly type = "[Orders] save dispatch notes";
  constructor(
    public orderId: string,
    public dispatchNotes: string,
    public callback: (error) => void
  ) {}
}

export class UpdateLaborOrdersFilters {
	static readonly type = "[Orders] update filters";
	constructor(
		public filters: LaborOrdersFilters
	) {}
}

export class DuplicateOrder {
  static readonly type = "[Orders] duplicate order";
  constructor(
    public orderId: string,
    public payload: any
  ) {}
}